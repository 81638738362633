import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import Header from "../modules/header";
import { RecentSubmissions } from "../modules/recent-submission";

export const query = graphql`
  query PolicyAreaTemplateQuery($id: String!) {
    page: sanityPolicyArea(id: { eq: $id }) {
      title
      excerpt
      slug {
        current
      }
      ...policyAreaBuilderFields
      heroTitle
      heroText
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      breadcrumbs {
        title
        slug
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
          }
        }
      }
      taxonomy {
        topic {
          title
          parentTopic
        }
        tags {
          title
        }
      }
    }
    relatedSubmissions: allSanityPolicySubmission(
      sort: { order: DESC, fields: publishDate }
      filter: {
        relatedPolicy: { id: { eq: $id } }
        policyPosition: { ne: true }
      }
    ) {
      edges {
        node {
          title
          publishDate
          relatedPolicy {
            title
          }
          path
          taxonomy {
            topic {
              title
            }
            tags {
              title
            }
          }
        }
      }
    }
  }
`;

const PolicyAreaTemplate = props => {
  const { data } = props;
  const page = data.page;
  const relatedSubmissions = data.relatedSubmissions.edges.map(edge => {
    return {
      ...edge.node,
      link: edge.node.path,
      topic: edge.node?.relatedPolicy?.title,
      mediaType: "Policy",
      date: edge.node.publishDate,
    };
  });

  return (
    <Layout className="pages">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.title}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        text={page.heroText}
        backgroundColour={"beige"}
      />
      <PageBuilder builder={page.pageBuilder} />
      {relatedSubmissions?.[0] && (
        <RecentSubmissions
          news={relatedSubmissions}
          title={"Related Submissions"}
        />
      )}
    </Layout>
  );
};

PolicyAreaTemplate.propTypes = {
  data: PropTypes.object,
};

export default PolicyAreaTemplate;
